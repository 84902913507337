import { ContainerImagem } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from '../../store/ControleMensagemReducer/types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import logoLeap from '../../assets/logoBranco.jpg';
import './styles.css';
import { GET_DATA_DESIGN, POST_AUTH, POST_DATA, PUT_AUTH, PUT_DATA, SalvarLogEventoSentry, SalvarLogSentry } from '../../services/API';
import { useNavigate } from 'react-router-dom';
import packageJson from '../../../package.json';
import { USUARIO_LOGADO_SET_DADOS } from '../../store/UsuarioLogadoReducer/types';
import { CircularProgress, FormControlLabel, Stack, Switch, Tab, Tabs, Typography, useTheme, Box, Button, FormControl, Grid, TextField, useMediaQuery } from '@mui/material';
import { getStorage, removeStorage, setStorage } from '../../hooks/useStorage';
import * as moment from 'moment';

import { SET_DADOS_ON_BOARD } from '../../store/OnBoard/types';
import { SUPER_USER_SET_DADOS } from '../../store/SuperUser/types';
import { LEAPLOVERS_SET_CLEAR } from '../../store/LeapLovers/types';
import { isEmail, valida_cpf_cnpj } from '../../services/utils/functions';
import { isMobile } from '../../services/DeviceServices';
import { TEMA_LOJA_SET } from '../../store/TemaLojaReducer/types';

const Imagem1 = 'https://leapstyle.blob.core.windows.net/crmlogin/01.png';
const Imagem2 = 'https://leapstyle.blob.core.windows.net/crmlogin/02.png';
const Imagem3 = 'https://leapstyle.blob.core.windows.net/crmlogin/03.png';
const Imagem4 = 'https://leapstyle.blob.core.windows.net/crmlogin/04.png';
const Imagem5 = 'https://leapstyle.blob.core.windows.net/crmlogin/05.png';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const themeContext = useTheme();
    const matchDownMd = useMediaQuery('(max-width: 1024px)');

    const [imagemBackground, setImagemBackground] = useState({ id: 1, imagem: Imagem1 });
    const [loading, setLoading] = useState(false);
    const [emailRecuperacao, setEmailRecuperacao] = useState('');
    const [dadosNovoUsuario, setDadosNovoUsuario] = useState({
        nome: '',
        arroba: '',
        email: '',
        celular: '',
        cnpj: '',
        senha: '',
        confirmarSenha: ''

    });

    const [dataLogin, setDataLogin] = useState({
        id: 0,
        login: '',
        senha: ''
    });

    const [value, setValue] = useState(0);
    const [aceitoTermosPoliticas, setAceitoTermosPoliticas] = useState(false);

    //Se veio para login limpar redux
    useEffect(() => {
        dispatch({
            type: USUARIO_LOGADO_SET_DADOS,
            data: null
        });
        dispatch({
            type: SET_DADOS_ON_BOARD,
            data: null
        });
        dispatch({
            type: SUPER_USER_SET_DADOS,
            data: null
        });
        dispatch({
            type: LEAPLOVERS_SET_CLEAR,
            data: null
        });
        removeStorage('usrin');
        removeStorage('onBoard');
        removeStorage('superUsrin');
        removeStorage('usrLeapVesti');
        removeStorage('usrLeapLovers');
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (imagemBackground.id === 1)
                setImagemBackground({ id: 2, imagem: Imagem2 });
            else if (imagemBackground.id === 2)
                setImagemBackground({ id: 3, imagem: Imagem3 });
            else if (imagemBackground.id === 3)
                setImagemBackground({ id: 4, imagem: Imagem4 });
            else if (imagemBackground.id === 4)
                setImagemBackground({ id: 5, imagem: Imagem5 });
            else
                setImagemBackground({ id: 1, imagem: Imagem1 });
        }, 8000);

    }, [imagemBackground]);

    const handleSubmit = async (values, flgCadastroNovo = false) => {
        try {
            setLoading(true);

            const login = await PUT_AUTH(values.login, values.senha);

            if (login === null) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'E-mail ou senha está incorreto',
                });
                setLoading(false);
                return;
            }

            if (!login?.FlgAtivo) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'No momento esta loja esta inativa, favor contatar o suporte.',
                });
                setLoading(false);
                return;
            }

            if (login.FlgSuperUser) {
                const superUser = {
                    IdUsuario: login.IdUsuario,
                    Email: login.Email
                }

                setStorage('superUsrin', JSON.stringify(superUser));
                dispatch({
                    type: SUPER_USER_SET_DADOS,
                    data: superUser
                });
                navigate('/lojas', { replace: true });
            }
            else {
                const user = await POST_DATA(`Usuario/GetUsuarioById?IdUsuario=${login.IdUsuario}&join=true`);
                const usuarioEmpresa = await POST_DATA(`UsuarioEmpresa/GetUsuarioEmpresaByIdUsuario?IdUsuario=${login.IdUsuario}`);

                if (user.CodigoIndicado !== 'LEAP-545' && usuarioEmpresa === null) {
                    dispatch({
                        type: CONTROLEMENSAGEM_AVISO,
                        tipoComponente: 'alert',
                        titulo: 'Usuário app sem empresa',
                        message: 'Olá, como você iniciou o seu cadastro pelo Aplicativo é necessário concluir o processo nele para conseguir seguir. Obrigado.'
                    });
                    setLoading(false);
                    return;
                }

                const usuarioLogado = {
                    IdUsuario: user.IdUsuario,
                    IdEndereco: user._Endereco[0]?.END_Id,
                    Endereco: user._Endereco[0],
                    Celular: user.Celular,
                    NomeFantasia: usuarioEmpresa?.NomeFantasia,
                    IdUsuarioEmpresa: usuarioEmpresa.IdUsuarioEmpresa,
                    IdTema: user.IdTema,
                    Email: user.Email,
                    Cnpj: usuarioEmpresa.Cnpj,
                    CodigoIndicado: user.CodigoIndicado,
                    MetaFaturamento: usuarioEmpresa.metaFaturamento,
                    MetaVenda: usuarioEmpresa.metaVenda,
                    PlanoEmpresa: usuarioEmpresa.UsuarioEmpresaPlano,
                    TipoAssinaturaPlano: usuarioEmpresa.TipoAssinaturaPlano,
                    FlgCodigoAcesso: login.FlgCodigoAcesso,
                    FlgLiberadaLojaOnline: usuarioEmpresa.FlgLiberadaLojaOnline,
                    DataCadastroEmpresa: usuarioEmpresa.DataCadastro,
                    Time: moment().add(4, 'hours')
                };

                setStorage('usrin', JSON.stringify(usuarioLogado));

                let objOnboard = null;
                const onboardResult = await POST_DATA(`OnboardingStore/GetAllOnboardingStoreByValorExato?strValorExato=${usuarioEmpresa.IdUsuarioEmpresa}&ColunaParaValorExato=OS.IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=OS.IdOnboardingStore&Log=`);

                const onboardLojistaResult = await POST_DATA(`Questionario/GetPerguntaRespostaQuestionarioId?idQuestionario=1&idUsuario=${usuarioLogado.IdUsuario}`);
                //const onboardDesignSiteResult = await POST_DATA(`Questionario/GetPerguntaRespostaQuestionarioId?idQuestionario=2&idUsuario=${usuarioLogado.IdUsuario}`);

                const obj = {
                    userName: process.env.REACT_APP_LOGIN_DESIGN,
                    password: process.env.REACT_APP_SENHA_DESIGN
                };

                const loginDesign = await POST_AUTH(obj);
                const construtorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${usuarioLogado.IdUsuario}`, loginDesign.token);

                if (construtorSite?.data?.id > 0 && construtorSite?.data?.arquivo?.idLoja > 0) {
                    dispatch({
                        type: TEMA_LOJA_SET,
                        data: construtorSite?.data?.arquivo
                    });
                }

                if (onboardResult === null || onboardResult.length === 0) //Salvar no banco um novo onBoard
                {
                    const newOnboard = {
                        IdUsuarioEmpresa: usuarioEmpresa.IdUsuarioEmpresa,
                        IdUltimoOnboardingStoreItem: 0,
                        FlagCompletado: false,
                        FlagAtivo: true
                    }

                    objOnboard = await PUT_DATA(`OnboardingStore/CadastroOnboardingStore`, newOnboard);
                }
                else {
                    objOnboard = onboardResult[0];
                }

                dispatch({
                    type: SET_DADOS_ON_BOARD,
                    data: objOnboard
                });

                dispatch({
                    type: USUARIO_LOGADO_SET_DADOS,
                    data: usuarioLogado
                });

                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso ao logar'
                });

                if (flgCadastroNovo) {
                    SalvarLogEventoSentry('Novo Cadastro');
                }

                setLoading(false);
                if (onboardLojistaResult.totalPerguntaRespondida < onboardLojistaResult.totalPergunta)
                    navigate('/onboard-lojista');
                // else if (onboardDesignSiteResult.totalPerguntaRespondida < onboardDesignSiteResult.totalPergunta || construtorSite.data.id === 0)
                //     navigate('/on-board-design-site');
                else if (!objOnboard.FlagCompletado)
                    navigate('/on-board');
                else
                    navigate('/dashboard');
            }
        } catch (error) {
            setLoading(false);
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: error?.tipoComponente,
                titulo: error?.titulo,
                message: error.message,
            });
        }
    };

    const handleCreateCount = async () => {
        try {
            setLoading(true);

            const possuiUsuarioByCnpj = await POST_DATA(`Usuario/GetAllUsuarioByValorExato?strValorExato=${dadosNovoUsuario.cnpj}&ColunaParaValorExato=Cpf&fSomenteAtivos=false&join=true&maxInstances=0&order_by=IdUsuario&Log=`);
            if (possuiUsuarioByCnpj.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Ola, já existe uma conta com esse CNPJ, se voce não lembra a senha , entre em contato com o suporte ou resete sua senha.',
                });

                setLoading(false);
                return;
            }

            const possuiUsuarioByEmail = await POST_DATA(`Usuario/GetAllUsuarioByValorExato?strValorExato=${dadosNovoUsuario.email}&ColunaParaValorExato=Email&fSomenteAtivos=false&join=true&maxInstances=0&order_by=IdUsuario&Log=`);
            if (possuiUsuarioByEmail.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Ola, já existe uma conta registrada com esse email. Caso não lembre da senha, entre em contato com o suporte ou resete sua senha.',
                });

                setLoading(false);
                return;
            }

            let msg = '';

            const cpfCnpjOk = valida_cpf_cnpj(dadosNovoUsuario.cnpj);

            if (dadosNovoUsuario.nome.length === 0 || dadosNovoUsuario.nome.length < 3)
                msg += `\nFavor informar um nome ou apelido`;
            else if (isEmail(dadosNovoUsuario.nome))
                msg += `\nFavor informar um nome ou apelido válido`;

            if (dadosNovoUsuario.arroba.length === 0)
                msg += `\nFavor informar seu arroba (@)`;
            if (dadosNovoUsuario.email.length === 0 || dadosNovoUsuario.email.length < 3 || !isEmail(dadosNovoUsuario.email))
                msg += `\nFavor informar um e-mail válido`;
            if (dadosNovoUsuario.celular.length === 0 || dadosNovoUsuario.celular.length < 11)
                msg += `\nFavor informar um número de celular válido`;
            if (!cpfCnpjOk)
                msg += `\nFavor informar um ${dadosNovoUsuario.cnpj.length <= 11 ? 'CPF' : 'CNPJ'} válido`;

            if (dadosNovoUsuario.senha.length === 0 || dadosNovoUsuario.senha.length < 6)
                msg += `\nFavor informar uma senha de no mínimo 6 caracteres`;
            if (dadosNovoUsuario.senha.length >= 6 && (dadosNovoUsuario.senha !== dadosNovoUsuario.confirmarSenha))
                msg += `\nAs senhas informadas são diferentes`;

            if (dadosNovoUsuario.nome.toUpperCase().includes('TST') || dadosNovoUsuario.nome.toUpperCase().includes('TESTE') || dadosNovoUsuario.nome.toUpperCase().includes('HOMOLOGACAO') || dadosNovoUsuario.nome.toUpperCase().includes('HOMOLOG') || dadosNovoUsuario.nome.toUpperCase().includes('TEST') || dadosNovoUsuario.nome.toUpperCase().includes('TRIAL') || dadosNovoUsuario.nome.toUpperCase().includes('DEV'))
                msg += `\nO nome de usuário não permite o uso desta palavra`;

            if (dadosNovoUsuario.email.toUpperCase().includes('TST') || dadosNovoUsuario.email.toUpperCase().includes('TESTE') || dadosNovoUsuario.email.toUpperCase().includes('TEST') || dadosNovoUsuario.email.toUpperCase().includes('HOMOLOGACAO') || dadosNovoUsuario.email.toUpperCase().includes('HOMOLOG') || dadosNovoUsuario.email.toUpperCase().includes('TRIAL') || dadosNovoUsuario.email.toUpperCase().includes('DEV'))
                msg += `\nO e-mail de usuário não permite o uso desta palavra`;

            if (msg.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: msg,
                });
                setLoading(false);
                return;
            }

            const dadosLogin = {
                login: dadosNovoUsuario.email,
                senha: dadosNovoUsuario.senha
            }

            const objUsuario = {
                Nome: dadosNovoUsuario.nome,
                Email: dadosNovoUsuario.email,
                Senha: dadosNovoUsuario.senha,
                Celular: dadosNovoUsuario.celular,
                Cpf: dadosNovoUsuario.cnpj,
                NickName: dadosNovoUsuario.arroba,
                CodigoIndicado: 'LEAP-545',
                DtValidadeAccessToken: moment().format('YYYY-MM-DD'),
                SaltKey: "",
                AccessToken: "",
                IdUsuarioOrigem: 188,
                FlgLoja: true
            }

            const usuario = await PUT_DATA(`Usuario/CadastroUsuario`, objUsuario);
            if (usuario !== null) {
                const objUsuarioLoja = {
                    IdUsuario: usuario.IdUsuario,
                    NomeFantasia: usuario.Nome,
                    RazaoSocial: usuario.Nome,
                    Cnpj: usuario.Cpf,
                    IdTipoAssinaturaPlano: 1, //Mensal
                    IdUsuarioEmpresaPlano: 1 //Nova York
                }

                await PUT_DATA(`Usuario/AceiteTermos?IdUsuario=${usuario.IdUsuario}`, null);
                await PUT_DATA(`Usuario/AceitePolitica?IdUsuario=${usuario.IdUsuario}`, null);
                await PUT_DATA(`UsuarioEmpresa/InsertUsuarioEmpresa`, objUsuarioLoja);

                setDadosNovoUsuario({
                    nome: '',
                    arroba: '',
                    email: '',
                    celular: '',
                    cnpj: ''
                });

                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Sucesso ao criar a nova loja',
                });

                await POST_DATA(`Log/InsertLogModulosUsuario?idUsuario=${usuario.IdUsuario}&sModulo=Device&sDescricao=${isMobile() ? 'Mobile' : 'Desktop'}`);

                await handleSubmit(dadosLogin, true);
            }

            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: err?.message,
            });
        }
    }

    const handleRecuperarSenha = async () => {
        try {
            setLoading(true);

            if (emailRecuperacao.length === 0 || emailRecuperacao.length < 3) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Favor informar um e-mail válido`,
                });
                setLoading(false);
                return;
            }

            const result = await POST_DATA(`/Usuario/RecoveryPasswordEmail?sEmail=${emailRecuperacao}&isProfessional=false&idUsuario=188`, null);
            if (result) {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: result.mensagem,
                });
            }

            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: err.message ? err.message : 'Ocorreu um erro ao tentar resetar a senha',
            });
        }
    }

    const validationSchema = Yup.object().shape({
        login: Yup.string().required('Campo obrigatório'),
        senha: Yup.string().required('Campo obrigatório').min(6, 'A senha deve ter no mínimo 6 caracteres'),
    });

    const formik = useFormik({
        onSubmit: (values) => handleSubmit(values, false),
        initialValues: dataLogin,
        validationSchema: validationSchema,
        validateOnChange: false,
        enableReinitialize: true,
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container>
                {
                    !matchDownMd &&
                    <Grid className='login-fundo oculta-mobile' item xs={12} sm={6} md={6} lg={8} style={{ transition: 'all 1.8s ease-out', backgroundImage: `url(${imagemBackground.imagem})` }}>
                        <ContainerImagem urlImagem='../../assets/telaLogin2.png' className='background-imagem' />
                    </Grid>
                }
                <Grid item xs={12} sm={matchDownMd ? 12 : 6} md={matchDownMd ? 12 : 6} lg={4} style={{ minHeight: window.innerWidth <= 1536 && window.innerHeight <= 800 ? '800px' : '1024px', display: 'flex', alignItems: window.innerWidth <= 1536 && window.innerHeight <= 800 ? 'flex-start' : 'center', justifyContent: 'center', paddingTop: window.innerWidth <= 1536 && window.innerHeight <= 800 ? 50 : 0 }}>
                    <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginLeft: 50, marginRight: 50 }}>
                        {
                            loading ? (
                                <Stack justifyContent={"center"} alignItems={"center"} style={{ marginTop: 300 }}>
                                    <CircularProgress color="secondary" />
                                    <Typography variant="h5" sx={{ mt: 4 }}>
                                        Entrando...
                                    </Typography>
                                </Stack>
                            ) : (
                                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: isMobile() ? 340 : null }}>
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                        <img src={logoLeap} alt="logo" style={{ width: '200px', marginBottom: 15, filter: 'invert(1)' }} />
                                    </Box>
                                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', margin: "1rem 0" }}>
                                        <Typography sx={{ textAlign: 'center', fontFamily: "Roboto, Helvetica, Arial, sans-serif" }} variant="h5">
                                            Ola, Bem Vindo(a)
                                        </Typography>
                                        <Typography sx={{ textAlign: 'center', marginTop: 1, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }} variant="h7">
                                            Gerencie de forma simples sua loja
                                        </Typography>
                                    </Box>

                                    {
                                        process.env.REACT_APP_START_LOGIN_OPCAO == 1 &&
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{ width: '100%', minWidth: 400 }}>
                                            <Tab label="Login" style={{ marginLeft: -20 }} />
                                            <Tab label="/" disabled style={{ marginLeft: -30, marginRight: -30 }} />
                                            <Tab label="Nova Conta" />
                                            <Tab label="/" disabled style={{ marginLeft: -30, marginRight: -30 }} />
                                            <Tab label="Resetar" style={{ marginLeft: 0 }} />
                                        </Tabs>
                                    }
                                    {
                                        process.env.REACT_APP_START_LOGIN_OPCAO == 2 &&
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{ width: '100%', minWidth: 400 }}>
                                            <Tab label="Nova Conta" />
                                            <Tab label="/" disabled style={{ marginLeft: -30, marginRight: -30 }} />
                                            <Tab label="Login" style={{ marginLeft: -20 }} />
                                            <Tab label="/" disabled style={{ marginLeft: -30, marginRight: -30 }} />
                                            <Tab label="Resetar" style={{ marginLeft: 0 }} />
                                        </Tabs>
                                    }


                                    {
                                        ((process.env.REACT_APP_START_LOGIN_OPCAO == 1 && value === 0) || (process.env.REACT_APP_START_LOGIN_OPCAO == 2 && value === 2)) &&
                                        <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', paddingTop: 20, paddingBottom: 20 }}>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label='E-mail'
                                                    name='login'
                                                    value={formik.values.login}
                                                    onChange={formik.handleChange}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                <TextField
                                                    style={{ marginTop: 10 }}
                                                    variant="outlined"
                                                    fullWidth
                                                    type='password'
                                                    label='Senha'
                                                    name='senha'
                                                    value={formik.values.senha}
                                                    onChange={formik.handleChange}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                />
                                                <span className="msg-error">{formik.errors.senha}</span>
                                            </FormControl>
                                            <Button variant='text' style={{ marginTop: 10 }} onClick={() => setValue(4)}>
                                                Esqueci minha senha
                                            </Button>
                                            <Button
                                                disabled={formik.getFieldProps('login').value.length === 0 || formik.getFieldProps('senha').value.length === 0}
                                                size="large"
                                                color="primary"
                                                type='submit'
                                                variant='contained'
                                                style={{
                                                    width: '100%',
                                                    marginTop: 10,
                                                    fontSize: 16,
                                                    mt: 2,
                                                    background: formik.getFieldProps('login').value.length === 0 || formik.getFieldProps('senha').value.length === 0 ? 'rgb(224, 224, 224)' : "rgb(247, 147, 30)",
                                                    color: 'white', fontWeight: 'bold'
                                                }}
                                            >
                                                Acessar Conta
                                            </Button>
                                        </Box>
                                    }
                                    {
                                        ((process.env.REACT_APP_START_LOGIN_OPCAO == 1 && value === 2) || (process.env.REACT_APP_START_LOGIN_OPCAO == 2 && value === 0)) &&
                                        <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', paddingTop: 20, paddingBottom: 20 }}>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label='Nome ou apelido'
                                                    name='nome'
                                                    value={dadosNovoUsuario.nome}
                                                    onChange={(e) => setDadosNovoUsuario({ ...dadosNovoUsuario, nome: e.target.value })}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '12px'
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                <TextField
                                                    style={{ marginTop: 10 }}
                                                    variant="outlined"
                                                    fullWidth
                                                    label='Seu @'
                                                    name='arroba'
                                                    value={dadosNovoUsuario.arroba}
                                                    onChange={(e) => setDadosNovoUsuario({ ...dadosNovoUsuario, arroba: e.target.value })}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                <TextField
                                                    style={{ marginTop: 10 }}
                                                    variant="outlined"
                                                    fullWidth
                                                    label='E-mail'
                                                    name='emailUsuario'
                                                    value={dadosNovoUsuario.email}
                                                    onChange={(e) => setDadosNovoUsuario({ ...dadosNovoUsuario, email: e.target.value })}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                <TextField
                                                    style={{ marginTop: 10 }}
                                                    variant="outlined"
                                                    fullWidth
                                                    label='Celular'
                                                    name='celular'
                                                    value={dadosNovoUsuario.celular}
                                                    onChange={(e) => setDadosNovoUsuario({ ...dadosNovoUsuario, celular: e.target.value.replace(/\D/g, "") })}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                    inputProps={{
                                                        maxLength: 11,
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                <TextField
                                                    style={{ marginTop: 10 }}
                                                    variant="outlined"
                                                    fullWidth
                                                    label='CPF ou CNPJ'
                                                    name='cnpj'
                                                    value={dadosNovoUsuario.cnpj}
                                                    onChange={(e) => setDadosNovoUsuario({ ...dadosNovoUsuario, cnpj: e.target.value.replace(/\D/g, "") })}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                    inputProps={{
                                                        maxLength: 14,
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                <TextField
                                                    style={{ marginTop: 10 }}
                                                    variant="outlined"
                                                    type='password'
                                                    fullWidth
                                                    label='Senha'
                                                    name='senha'
                                                    value={dadosNovoUsuario.senha}
                                                    onChange={(e) => setDadosNovoUsuario({ ...dadosNovoUsuario, senha: e.target.value })}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                    inputProps={{
                                                        maxLength: 20,
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                <TextField
                                                    style={{ marginTop: 10 }}
                                                    variant="outlined"
                                                    type='password'
                                                    fullWidth
                                                    label='Confirmar Senha'
                                                    name='confirmarSenha'
                                                    value={dadosNovoUsuario.confirmarSenha}
                                                    onChange={(e) => setDadosNovoUsuario({ ...dadosNovoUsuario, confirmarSenha: e.target.value })}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                    inputProps={{
                                                        maxLength: 20,
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControlLabel
                                                style={{ marginTop: 10, marginBottom: -5 }}
                                                control={<Switch
                                                    color="primary"
                                                    checked={aceitoTermosPoliticas}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setAceitoTermosPoliticas(!aceitoTermosPoliticas);
                                                    }}
                                                />}
                                                label={<Typography style={{ zIndex: 999 }} onClick={() => window.open('https://apimobip.leapstyle.me/termos/termospolitica.html?page=1', '_blank')}>Aceito <span style={{ cursor: 'pointer', color: 'rgb(241, 65, 108)' }}>termos</span> e <span style={{ cursor: 'pointer', color: 'rgb(241, 65, 108)' }}>políticas</span> de privacidade</Typography>}
                                            />
                                            <Button
                                                disabled={!aceitoTermosPoliticas}
                                                readOnly={!aceitoTermosPoliticas}
                                                size="large"
                                                color="primary"
                                                variant='contained'
                                                onClick={() => handleCreateCount()}
                                                style={{
                                                    width: '100%',
                                                    marginTop: 20,
                                                    fontSize: 16,
                                                    mt: 2,
                                                    background: aceitoTermosPoliticas ? 'rgb(247, 147, 30)' : '#c4c4c4',
                                                    color: 'white', fontWeight: 'bold'
                                                }}
                                            >
                                                Criar Conta
                                            </Button>
                                        </Box>
                                    }
                                    {
                                        value === 4 &&
                                        <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', paddingTop: 20, paddingBottom: 20 }}>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label='E-mail para recuperação'
                                                    name='nome'
                                                    value={emailRecuperacao}
                                                    onChange={(e) => setEmailRecuperacao(e.target.value)}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontSize: '14px'
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                            <Button
                                                size="large"
                                                color="primary"
                                                variant='contained'
                                                onClick={() => handleRecuperarSenha()}
                                                style={{
                                                    width: '100%',
                                                    marginTop: 20,
                                                    fontSize: 16,
                                                    mt: 2,
                                                    background: 'rgb(247, 147, 30)',
                                                    color: 'white', fontWeight: 'bold'
                                                }}
                                            >
                                                Recuperar Senha
                                            </Button>
                                        </Box>
                                    }

                                    <Box style={{ width: '100%', marginTop: 5, marginBottom: 20, display: 'flex', justifyContent: 'center', color: 'gray', fontFamily: themeContext.typography.fontFamily }}>
                                        Versão: {packageJson.version} | © 2024 | <span style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => window.open('https://leapstyle.me/', '_blank')}>@LeapStyle.me</span>
                                    </Box>
                                </Box>
                            )
                        }
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}
export default Login;

