import { Container } from "./styles";
import ImagemOnboard from '../../../assets/imagem-onboard-ljista.png'
import { Box, Grid, TextField, Typography, Button, useMediaQuery, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from "react";
import { SET_MENU } from "../../../store/Customization/types";
import { handleDownload, POST_DATA, PUT_DATA, SalvarLogSentry, UploadFileAzureText } from "../../../services/API";
import * as moment from "moment";

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { useNavigate } from "react-router";

import fireworks from '../../../assets/fireworks.gif';
import LoadingAllPage from "../../../components/LoadingAllPage";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import { isMobile } from "../../../services/DeviceServices";
import { atualizarOnboardStore, validarDominio } from "../../../services/utils/functions";
import { SET_DADOS_ON_BOARD } from "../../../store/OnBoard/types";

const OnBoardLojista = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const superUser = useSelector((state) => state.superUser);
    const onBoard = useSelector((store) => store.onBoard);

    const [dadosOnboard, setDadosOnboard] = useState(null);
    const [ultimaPagina, setUltimaPagina] = useState(0);
    const [qtdSessaoDominiPagina, setQtdSessaoDominiPagina] = useState(0);

    const [openModalAviso, setOpenModalAviso] = useState(false);

    const [salvandoDados, setSalvandoDados] = useState(false);

    const [dominio, setDominio] = useState({
        IdDominioLEAP: 0,
        IdUsuarioEmpresa: 0,
        IdProvedor: 0,
        IpServidor: '20.51.255.252',
        NomeSiteLeap: '',
        NomeSiteDominioGeral: '',
        DataCriacao: '',
        DataAtualizacao: null,
        FlagAtivo: true
    });

    const [loadingMensagem, setLoadingMensagem] = useState(false);
    const [verificandoLoadingInicial, setVerificandoLoadingInicial] = useState(true);

    const telaMenorIgual1024 = useMediaQuery('(max-width: 1024px)');
    const telaMenorIgual1250 = useMediaQuery('(max-width: 1250px)');
    const telaMenorIgual880 = useMediaQuery('(max-width: 880px)');

    useEffect(() => {
        if (userLoggedIn === null) return;

        try {
            (async () => {
                //Onboard Lojista
                setLoadingMensagem(true);

                let onboardLojistaResult = await POST_DATA(`Questionario/GetPerguntaRespostaQuestionarioId?idQuestionario=1&idUsuario=${userLoggedIn.IdUsuario}`);

                setLoadingMensagem(false);
                setVerificandoLoadingInicial(false);

                if (onboardLojistaResult.totalPerguntaRespondida >= onboardLojistaResult.totalPergunta && superUser === null) {
                    navigate('/on-board');
                }

                onboardLojistaResult = { ...onboardLojistaResult, paginaAtual: 0 };

                let contador = 1;
                for (const resposta of onboardLojistaResult._QuestionarioPergunta) {
                    resposta.pagina = contador;
                    contador++;
                }

                setQtdSessaoDominiPagina(contador - 1);
                setUltimaPagina(contador);
                setDadosOnboard(onboardLojistaResult);

                setOpenModalAviso(isMobile());
            })();

            dispatch({ type: SET_MENU, opened: false });

            const handleBeforeUnload = (event) => {
                // Perform actions before the component unloads

                event.preventDefault();
                event.returnValue = 'Você realmente deseja sair do site sem finalizar?';
            };

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            }
        }
        catch (err) {
            const obj = {
                Descricao: 'Erro ao buscar dados tela do questionário 1',
                Erro: err,
                Controller: 'CRM',
                DataErro: moment().format('yyyy-MM-DD HH:mm:ss')
            };
            PUT_DATA(`/LogErro/CadastroLogErro`, obj);

            SalvarLogSentry(err);
        }
        finally {
            setVerificandoLoadingInicial(false);
        }
    }, [userLoggedIn]);

    // useEffect(() => {
    //     console.log('dadosOnboard', dadosOnboard);
    // }, [dadosOnboard]);

    const handleCheckResposta = (idPergunta, idResposta, text = '') => {
        const perguntas = [...dadosOnboard._QuestionarioPergunta];

        dadosOnboard._QuestionarioPergunta.forEach(pergunta => {
            if (pergunta.IdQuestionarioPergunta === idPergunta) {
                pergunta._QuestionarioResposta.forEach(resposta => {
                    if (pergunta.Tipo === 'RADIO') {
                        if (resposta.IdQuestionarioResposta === idResposta) {
                            resposta.Checked = !resposta.Checked
                        }
                        else {
                            resposta.Checked = false;
                        }
                    }
                    else if (pergunta.Tipo === 'CHECK' && resposta.IdQuestionarioResposta === idResposta) {
                        resposta.Checked = !resposta.Checked
                    }
                    else if (pergunta.Tipo === 'TEXT' && resposta.IdQuestionarioResposta === idResposta) {
                        resposta.RespostaTexto = text;
                    }
                });
            }
        });

        setDadosOnboard({ ...dadosOnboard, _QuestionarioPergunta: perguntas });
    }

    const handleConcluir = async () => {
        try {
            const respostasSalvar = [];

            setSalvandoDados(true);

            for (const pergunta of dadosOnboard._QuestionarioPergunta) {
                for (const resposta of pergunta._QuestionarioResposta.filter(a => a.Checked || a.RespostaTexto?.length > 0)) {
                    respostasSalvar.push({
                        "IdQuestionario": pergunta.IdQuestionario,
                        "IdQuestionarioPergunta": pergunta.IdQuestionarioPergunta,
                        "IdQuestionarioResposta": resposta.IdQuestionarioResposta,
                        "IdUsuario": userLoggedIn.IdUsuario,
                        "RespostaTexto": resposta.RespostaTexto || '',
                        "FlagAtivo": true,
                        "DataInclusao": moment().format('YYYY-MM-DD HH:mm:ss')
                    });
                }
            }

            await PUT_DATA(`Questionario/InsertQuestionarioUsuario`, respostasSalvar);

            await handleSalvarDominio();

            Swal.fire({
                backdrop: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                title: "Parabéns, Bem Vindo a LeapStyle. Agora vamos configurar sua Loja.",
                width: 600,
                padding: "3em",
                color: "#33cccc",
                imageUrl: fireworks,
                imageHeight: 200,
                imageAlt: "Finalizar",
                confirmButtonText: "Continuar",
                confirmButtonColor: '#F1416C'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/on-board`);
                }
            });
        }
        catch (err) {
            setSalvandoDados(false);
            SalvarLogSentry(err);
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                titulo: 'Erro',
                message: 'Ocorreu um erro ao salvar os dados',
                tipoComponente: 'alert'
            });
        }
    }

    const handleValidarDominioLeap = async (mostrarMensagem = true) => {
        if (dominio.NomeSiteLeap.length < 5) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Favor informar um domínio válido',
            });
            return false;
        }

        let dominioVerificacao = dominio.NomeSiteLeap;
        dominioVerificacao = dominioVerificacao.replaceAll('.leapstyle.com.br', '');
        dominioVerificacao = `${dominioVerificacao}.leapstyle.com.br`;

        if (!validarDominio(dominioVerificacao)) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Favor informar um domínio válido para sua loja.\nSó é permitido um domínio com letras e números sem espaço.',
            });
            return false;
        }

        const dominios = await POST_DATA(`DominioLEAP/GetAllDominioLEAPByValorExato?strValorExato=${dominioVerificacao}&ColunaParaValorExato=NomeSiteLeap&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdDominioLEAP`);
        if (dominios.length > 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Domínio já está em uso por outra loja',
            });

            return false;
        }
        else {
            if (mostrarMensagem) {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Domínio disponível para uso',
                });
            }

            return true;
        }
    }

    const handleSalvarDominio = async () => {
        try {
            const dominioStr = dominio.NomeSiteLeap.trim().replaceAll('.leapstyle.me', '').replaceAll('leapstyle.me', '').replaceAll('.leapstyle.com.br', '').replaceAll('leapstyle.com.br', '').replaceAll('https://', '').replaceAll('http://', '').replaceAll('www.', '');

            dominio.NomeSiteLeap = `${dominioStr}.leapstyle.com.br`;
            dominio.IdProvedor = 16; //Sem provedor

            if (!validarDominio(dominio.NomeSiteLeap)) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Favor informar um domínio válido para sua loja.\nSó é permitido um domínio com letras e números sem espaço.',
                });
                return;
            }

            dominio.NomeSiteLeap = dominio.NomeSiteLeap.toLowerCase().trim();
            dominio.NomeSiteDominioGeral = dominio.NomeSiteLeap;
            dominio.IdUsuarioEmpresa = userLoggedIn.IdUsuarioEmpresa;

            let urlDominiosJson = "https://leapstyle.blob.core.windows.net/cms/config/dominios.json";
            if (process.env.REACT_APP_AMBIENTE === 'DEV')
                urlDominiosJson = "https://leapstyle.blob.core.windows.net/cms/config/homolog/dominios.json";

            const objDominios = await handleDownload(urlDominiosJson);

            let encontrou = false;
            objDominios.forEach((item) => {
                if (item.idUsuarioEmpresa == dominio.IdUsuarioEmpresa) {
                    item.cnpj = userLoggedIn.Cnpj.replaceAll(".", "").replace("/", "").replace("-", "");
                    item.dominio = dominio.NomeSiteLeap;

                    //item.dominioGeral = dominio.NomeSiteDominioGeral;
                    item.dominioGeral = dominio.NomeSiteLeap;

                    item.dataAlteracao = moment().format("YYY-MM-DD HH:mm:ss");
                    item.codigoLoja = `${moment(userLoggedIn.DataCadastroEmpresa).format('YYYYMMDDHHmmss')}${userLoggedIn.IdUsuario}`;
                    encontrou = true;
                }
            });

            if (!encontrou) {
                objDominios.push({
                    "cnpj": userLoggedIn.Cnpj.replaceAll(".", "").replace("/", "").replace("-", ""),
                    "idUsuarioEmpresa": userLoggedIn.IdUsuarioEmpresa,
                    "dominio": dominio.NomeSiteLeap,

                    //"dominioGeral": dominio.NomeSiteDominioGeral,
                    "dominioGeral": dominio.NomeSiteLeap,

                    "codigoLoja": `${moment(userLoggedIn.DataCadastroEmpresa).format('YYYYMMDDHHmmss')}${userLoggedIn.IdUsuario}`,
                    "dataAlteracao": moment().format("YYY-MM-DD HH:mm:ss")
                })
            }

            const base64 = btoa(JSON.stringify(objDominios));
            await UploadFileAzureText(base64, 'config', 'dominios.json', 'text/plain');

            const resultDominio = await POST_DATA(`DominioLEAP/GetAllDominioLEAPByValorExato?strValorExato=${userLoggedIn?.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdDominioLEAP&Log=`);
            if (resultDominio === null || resultDominio.length === 0) {
                const objSave = { ...dominio };
                objSave.IdUsuarioEmpresa = userLoggedIn.IdUsuarioEmpresa;
                objSave.NomeSiteLeap = objSave.NomeSiteLeap.trim();
                objSave.NomeSiteDominioGeral = objSave.NomeSiteDominioGeral.trim();
    
                if (objSave.IdDominioLEAP === 0) {
                    objSave.DataCriacao = moment().format("YYYY-MM-DD HH:mm:ss");
                }
                objSave.DataAtualizacao = moment().format("YYYY-MM-DD HH:mm:ss");
    
                await PUT_DATA(`DominioLEAP/CadastroDominioLEAP`, objSave);
            }


            if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 5)[0].FlagStatus) { //Liberar loja
                const objOnboard = { ...onBoard };
                objOnboard.IdUltimoOnboardingStoreItem = 5;

                const itemOnboard = objOnboard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 5)[0];
                itemOnboard.FlagStatus = true;

                await atualizarOnboardStore(objOnboard, itemOnboard);

                dispatch({
                    type: SET_DADOS_ON_BOARD,
                    data: objOnboard
                });
            }

        } catch (err) {
            const obj = {
                Descricao: 'Erro ao salvar dados da tela do questionário 1',
                Erro: err,
                Controller: 'CRM',
                DataErro: moment().format('yyyy-MM-DD HH:mm:ss')
            };
            PUT_DATA(`/LogErro/CadastroLogErro`, obj);

            SalvarLogSentry(err);
        }
    }

    try {
        return verificandoLoadingInicial ? <LoadingAllPage text={loadingMensagem ? "Agora vamos configurar a sua loja" : "Buscando dados..."} /> : <Container style={{ paddingLeft: isMobile() ? 20 : 70, paddingRight: isMobile() ? 20 : 70 }}>

            {/* <GoWhatsApp whatsNumber={`11976548907`} /> */}

            {/* {
            openModalAviso && <Modal
                key="modal"
                open={openModalAviso}
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: '50%',
                    left: '0%'
                }}
            >
                <Box
                    sx={[
                        container,
                        {
                            width: '100%',
                            maxWidth: 350,
                            height: '100%',
                            maxHeight: 300,
                            overflowY: 'auto',
                            borderRadius: 2,
                            backgroundColor: 'white',
                            display: 'flex',
                            flexDirection: 'column'
                        },
                    ]}
                >
                    <Typography style={{ fontWeight: 'bold', fontSize: 16, textAlign: 'center' }}>Para continuar, recomendamos o uso de um computador ou tablet para melhor desempenho e experiência.</Typography>

                    <Button variant="outlined" style={{ marginTop: 70 }} onClick={() => setOpenModalAviso(false)}>Continuar</Button>
                </Box>
            </Modal>
        } */}

            {
                (!dadosOnboard?.paginaAtual || dadosOnboard?.paginaAtual === 0) &&
                <Grid container spacing={2} style={{ paddingRight: isMobile() ? 10 : null }}>
                    <Grid item xs={12}>
                        <Typography style={{ fontSize: isMobile() ? 24 : 44, fontWeight: 'bold' }}>Olá, {userLoggedIn.NomeFantasia}</Typography>
                    </Grid>

                    <Grid item xs={isMobile() ? 12 : 6} style={{ marginTop: isMobile() ? 30 : null }}>
                        {
                            !isMobile() && <Typography style={{ fontSize: 20, width: '100%', paddingRight: isMobile() ? 10 : null, minWidth: isMobile() ? 290 : null, textAlign: isMobile() ? 'justify' : null }}>Divulgue seus produtos, potencialize
                                suas vendas, tenha um estoque
                                controlado. Crie uma história de sucesso!
                            </Typography>
                        }

                        {
                            isMobile() &&
                            <Typography style={{ fontSize: 20, marginTop: 20, width: '100%', paddingRight: isMobile() ? 10 : null, minWidth: isMobile() ? 290 : null, textAlign: isMobile() ? 'justify' : null }}>
                                Estamos prontos para configurar sua loja. Responda algumas perguntas simples para <span style={{ fontWeight: 'bold' }}>deixá-la pronta</span>.
                            </Typography>
                        }
                    </Grid>

                    <Grid item xs={isMobile() ? 12 : 6} style={{ marginTop: 20, display: 'flex', justifyContent: isMobile() ? 'center' : 'flex-end', maxHeight: 60, marginTop: isMobile() ? 50 : null }}>
                        <Button style={{ background: 'white', color: 'black', fontSize: 16, width: isMobile() ? 240 : 160, borderRadius: 20, fontWeight: 'bold' }} onClick={() => setDadosOnboard({ ...dadosOnboard, paginaAtual: 1 })}>
                            {isMobile() && <span>Configurar sua loja</span>}
                            {!isMobile() && <span>Começar</span>}
                        </Button>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 20, display: 'flex', justifyContent: 'center', marginTop: isMobile() ? 50 : telaMenorIgual1250 ? 100 : null }}>
                        <img src={ImagemOnboard} alt='Imagem Onboard Lojista' width={'100%'} style={{ width: 'auto', maxWidth: isMobile() ? 280 : 920 }} />
                    </Grid>
                </Grid>
            }

            {
                dadosOnboard?.paginaAtual > 0 && dadosOnboard && dadosOnboard?.paginaAtual !== qtdSessaoDominiPagina && dadosOnboard?.paginaAtual !== ultimaPagina && dadosOnboard._QuestionarioPergunta.filter(a => a.Posicao === dadosOnboard.paginaAtual).map(pergunta => {
                    return <Grid key={pergunta.IdQuestionarioPergunta} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: 24 }}>{pergunta.Descricao}</Typography>
                            {
                                pergunta.Tipo === 'RADIO' &&
                                <Typography style={{ fontSize: isMobile() ? 14 : 18 }}>Marque pelo menos 1 para seguir</Typography>
                            }
                            <Grid item xs={12} style={{ marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: isMobile() ? 'center' : null }}>
                                {
                                    pergunta._QuestionarioResposta.map(resposta => {
                                        return <Button key={resposta.IdQuestionarioResposta} style={{ background: resposta.Checked ? 'black' : 'white', color: resposta.Checked ? 'white' : 'black', padding: 10, fontSize: isMobile() ? 11.5 : 12.95, width: isMobile() ? 280 : 520, borderRadius: 20, fontWeight: 'bold', marginBottom: 15 }} onClick={() => handleCheckResposta(pergunta.IdQuestionarioPergunta, resposta.IdQuestionarioResposta)}>{resposta.Descricao}</Button>
                                    })
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ width: '100%', position: 'absolute', right: isMobile() ? 0 : null, bottom: isMobile() ? 60 : telaMenorIgual880 ? -120 : telaMenorIgual1024 ? -60 : telaMenorIgual1250 ? -30 : 60, marginTop: isMobile() ? 100 : 0, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingRight: isMobile() ? 35 : null, paddingLeft: isMobile() ? 35 : null }}>
                            {
                                !isMobile() &&
                                <Box style={{ display: 'flex', width: telaMenorIgual1024 ? '85%' : '90%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <img src='https://leapstyle.me/wp-content/uploads/2022/12/Logo-Leap-Branco-1024x407.png' width={150} style={{ marginRight: 100 }} />
                                    <Box>
                                        <Button style={{ background: 'white', color: 'black', fontSize: isMobile() ? 10 : 12.95, width: 60, borderRadius: 20 }} onClick={() => setDadosOnboard({ ...dadosOnboard, paginaAtual: pergunta.pagina - 1 })}><ArrowBackIosIcon /></Button>
                                        <Button disabled={pergunta._QuestionarioResposta.filter(a => a.Checked).length === 0} style={{ marginLeft: 10, background: pergunta._QuestionarioResposta.filter(a => a.Checked).length === 0 ? '#dbdbdb' : 'white', color: 'black', fontSize: 12.95, width: 60, borderRadius: 20 }} onClick={() => setDadosOnboard({ ...dadosOnboard, paginaAtual: pergunta.pagina + 1 })}><ArrowForwardIosIcon /></Button>
                                    </Box>
                                </Box>
                            }

                            {
                                isMobile() &&
                                <Box>
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                                        <img src='https://leapstyle.me/wp-content/uploads/2022/12/Logo-Leap-Branco-1024x407.png' width={100} />
                                    </Box>
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', paddingRight: 10 }}>
                                        <Button style={{ background: 'white', color: 'black', fontSize: 10, width: 40, borderRadius: 20 }} onClick={() => setDadosOnboard({ ...dadosOnboard, paginaAtual: pergunta.pagina - 1 })}><ArrowBackIosIcon style={{ fontSize: 20 }} /></Button>
                                        <Button disabled={pergunta._QuestionarioResposta.filter(a => a.Checked).length === 0} style={{ background: pergunta._QuestionarioResposta.filter(a => a.Checked).length === 0 ? '#dbdbdb' : 'white', color: 'black', fontSize: 10, width: 40, borderRadius: 20 }} onClick={() => setDadosOnboard({ ...dadosOnboard, paginaAtual: pergunta.pagina + 1 })}><ArrowForwardIosIcon style={{ fontSize: 20 }} /></Button>
                                    </Box>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                })
            }

            {
                dadosOnboard?.paginaAtual === qtdSessaoDominiPagina &&

                <Grid container spacing={2}>

                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', height: 'auto' }}>
                        <Typography style={{ fontWeight: 'bold', fontSize: 24 }}>Agora vamos definir um nome para o seu site (Loja) online</Typography>
                        <Typography style={{ fontSize: 18 }}>Verifique se o nome esta disponível abaixo :</Typography>

                        <Grid container spacing={3} style={{ marginTop: 20 }}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    InputLabelProps={{
                                        style: {
                                            color: 'black',
                                            fontSize: '14px'
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            color: 'black',
                                            fontSize: '14px'
                                        },
                                        endAdornment: <InputAdornment>
                                            <Typography style={{ color: '#c2c2c2' }}>+ .leapstyle.com.br</Typography>
                                        </InputAdornment>
                                    }}
                                    label={"Digite o domínio da sua loja"}
                                    placeholder={"joao"}
                                    inputProps={{ maxLength: 255 }}
                                    value={dominio.NomeSiteLeap}
                                    onChange={(e) => {
                                        setDominio({ ...dominio, NomeSiteLeap: e.target.value })
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} style={{ display: 'flex', justifyContent: isMobile() ? 'center' : null }}>
                                <Button variant="contained" onClick={() => handleValidarDominioLeap()}>Verificar disponibilidade</Button>
                            </Grid>

                        </Grid>

                    </Grid>

                    <Grid item xs={12} style={{ width: '100%', position: 'absolute', right: isMobile() ? 0 : null, bottom: isMobile() ? 60 : telaMenorIgual880 ? -120 : telaMenorIgual1024 ? -60 : telaMenorIgual1250 ? -30 : 60, marginTop: isMobile() ? 100 : 0, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingRight: isMobile() ? 35 : null, paddingLeft: isMobile() ? 35 : null }}>
                        {
                            !isMobile() &&
                            <Box style={{ display: 'flex', width: telaMenorIgual1024 ? '85%' : '90%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <img src='https://leapstyle.me/wp-content/uploads/2022/12/Logo-Leap-Branco-1024x407.png' width={150} style={{ marginRight: 100 }} />
                                <Box>
                                    <Button style={{ background: 'white', color: 'black', fontSize: isMobile() ? 10 : 12.95, width: 60, borderRadius: 20 }} onClick={() => setDadosOnboard({ ...dadosOnboard, paginaAtual: qtdSessaoDominiPagina - 1 })}><ArrowBackIosIcon /></Button>
                                    <Button disabled={dominio.NomeSiteLeap.length < 3} style={{ marginLeft: 10, background: dominio.NomeSiteLeap.length < 3 ? '#dbdbdb' : 'white', color: 'black', fontSize: 12.95, width: 60, borderRadius: 20 }} onClick={async () => {
                                        const res = await handleValidarDominioLeap(false);

                                        if (res) {
                                            setDadosOnboard({ ...dadosOnboard, paginaAtual: qtdSessaoDominiPagina + 1 });
                                        }

                                    }}><ArrowForwardIosIcon /></Button>
                                </Box>
                            </Box>
                        }

                        {
                            isMobile() &&
                            <Box>
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                                    <img src='https://leapstyle.me/wp-content/uploads/2022/12/Logo-Leap-Branco-1024x407.png' width={100} />
                                </Box>
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', paddingRight: 10 }}>
                                    <Button style={{ background: 'white', color: 'black', fontSize: 10, width: 40, borderRadius: 20 }} onClick={() => setDadosOnboard({ ...dadosOnboard, paginaAtual: qtdSessaoDominiPagina - 1 })}><ArrowBackIosIcon style={{ fontSize: 20 }} /></Button>
                                    <Button disabled={dominio.NomeSiteLeap.length < 3} style={{ background: dominio.NomeSiteLeap.length < 3 ? '#dbdbdb' : 'white', color: 'black', fontSize: 10, width: 40, borderRadius: 20 }} onClick={async () => {
                                        const res = await handleValidarDominioLeap(false);

                                        if (res) {
                                            setDadosOnboard({ ...dadosOnboard, paginaAtual: qtdSessaoDominiPagina + 1 });
                                        }

                                    }}><ArrowForwardIosIcon style={{ fontSize: 20 }} /></Button>
                                </Box>
                            </Box>
                        }
                    </Grid>

                </Grid>
            }

            {
                dadosOnboard?.paginaAtual === ultimaPagina &&
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', height: 'auto' }}>
                        <Typography style={{ fontSize: isMobile() ? 14 : 20, width: isMobile() ? 290 : 700 }}><span style={{ fontWeight: 'bold' }}>Temos um treinamento AO VIVO e totalmente GRATUITO</span> para você participar e iniciar da sua loja.</Typography>
                        <Typography style={{ fontSize: isMobile() ? 14 : 20, width: isMobile() ? 290 : 700, marginTop: isMobile() ? 50 : 30 }}>Não fique de fora, entre agora mesmo no grupo de WhatsApp da nossa equipe para receber todas as informações sobre o treinamento e mais dicas para acelerar a montagem da sua loja virtual.</Typography>
                        <Typography style={{ fontSize: isMobile() ? 14 : 17, width: isMobile() ? 290 : 700, marginTop: isMobile() ? 50 : 20 }}>Informe seu número WhatsApp</Typography>
                        <TextField
                            inputProps={{
                                maxLength: 11,
                            }}
                            style={{ width: isMobile() ? 290 : 350, marginTop: 10 }}
                            label={'Número whatsapp'}
                            value={dadosOnboard?._QuestionarioPergunta.filter(a => a.pagina === (ultimaPagina - 1))[0]?._QuestionarioResposta[0]?.RespostaTexto || ''}
                            onChange={(e) => {
                                handleCheckResposta(dadosOnboard?._QuestionarioPergunta.filter(a => a.pagina === (ultimaPagina - 1))[0].IdQuestionarioPergunta, dadosOnboard?._QuestionarioPergunta.filter(a => a.pagina === (ultimaPagina - 1))[0]?._QuestionarioResposta[0].IdQuestionarioResposta, e.target.value.replace(/([^\d])+/gim, ''));
                            }}
                        />
                        <Button disabled={salvandoDados || dadosOnboard?._QuestionarioPergunta.filter(a => a.pagina === (ultimaPagina - 1))[0]?._QuestionarioResposta[0]?.RespostaTexto === undefined || dadosOnboard?._QuestionarioPergunta.filter(a => a.pagina === (ultimaPagina - 1))[0]?._QuestionarioResposta[0]?.RespostaTexto?.length < 11} variant="contained" style={{ fontSize: 14, width: isMobile() ? 200 : 220, borderRadius: 20, marginRight: 10, marginTop: 15 }} onClick={() => handleConcluir()}>Concluir</Button>

                        {/* <Button variant="contained" style={{ background: 'white', color: 'black', fontSize: 14, width: 220, borderRadius: 20, marginTop: 15, marginRight: 10 }}>Não quero agora</Button>
                    <Button variant="contained" style={{ background: 'black', color: 'white', fontSize: 14, width: 220, borderRadius: 20, marginTop: 15, marginRight: 10 }}>Agendar treinamento</Button> */}
                    </Grid>

                    <Grid item xs={12} style={{ width: '100%', position: 'absolute', right: isMobile() ? 0 : null, paddingBottom: isMobile() ? 30 : null, marginTop: isMobile() ? 200 : 0, bottom: isMobile() ? 30 : telaMenorIgual880 ? -150 : telaMenorIgual1024 ? -60 : telaMenorIgual1250 ? -30 : 60, display: 'flex', flexDirection: isMobile() ? 'column' : 'row', justifyContent: isMobile() ? 'flex-end' : 'space-between', alignItems: 'flex-end', paddingRight: 15 }}>
                        {
                            !isMobile() &&
                            <Box style={{ display: 'flex', width: '90%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <img src='https://leapstyle.me/wp-content/uploads/2022/12/Logo-Leap-Branco-1024x407.png' width={150} style={{ marginRight: 150 }} />
                                <Button style={{ background: 'white', color: 'black', fontSize: 12.95, width: isMobile() ? 40 : 60, borderRadius: 20, marginRight: 10 }} onClick={() => setDadosOnboard({ ...dadosOnboard, paginaAtual: ultimaPagina - 1 })}><ArrowBackIosIcon style={{ fontSize: 20 }} /></Button>
                            </Box>
                        }
                        {
                            isMobile() &&
                            <Box style={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <img src='https://leapstyle.me/wp-content/uploads/2022/12/Logo-Leap-Branco-1024x407.png' width={100} />
                                </Box>
                                <Button style={{ background: 'white', color: 'black', fontSize: 10, width: isMobile() ? 40 : 60, borderRadius: 20, marginRight: 10 }} onClick={() => setDadosOnboard({ ...dadosOnboard, paginaAtual: ultimaPagina - 1 })}><ArrowBackIosIcon style={{ fontSize: 20 }} /></Button>
                            </Box>
                        }
                    </Grid>
                </Grid>
            }

        </Container>
    }
    catch (err) {
        const obj = {
            Descricao: 'Erro ao executar tela do questionário 1',
            Erro: err,
            Controller: 'CRM',
            DataErro: moment().format('yyyy-MM-DD HH:mm:ss')
        };
        PUT_DATA(`/LogErro/CadastroLogErro`, obj);

        dispatch({
            type: CONTROLEMENSAGEM_ERRO,
            titulo: 'Erro',
            message: `Ocorreu um erro inesperado\n${err}`,
            tipoComponente: 'alert'
        });

        SalvarLogSentry(err);
    }
}

export default OnBoardLojista;