import { useEffect, useRef, useState } from "react";
import { Container } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { POST_DATA, PUT_DATA, SalvarLogSentry } from "../../../services/API";
import { Box, Button, Divider, Paper, Tab, Tabs, Typography } from "@mui/material";
import LoadingAllPage from "../../../components/LoadingAllPage";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import useDialogConfirmationAsync from "../../../components/DialogConfirmation/DialogConfirmationAsync";

const Configuracao = () => {
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const carregouTela = useRef(null);
    const dispatch = useDispatch();
    const [getConfirmationSalvar, ConfirmationSalvar] = useDialogConfirmationAsync();

    const [loading, setLoading] = useState(false);
    const [loadingDados, setLoadingDados] = useState(false);

    //Itens
    const [generos, setGeneros] = useState([]);
    const [sexoXCategoria, setSexoXCategorias] = useState([]);
    const [pecas, setPecas] = useState([]);
    const [subPecas, setSubPecas] = useState([]);

    //Itens selecionados pela loja    
    const [generosLoja, setGenerosLoja] = useState([]);
    const [categoriasLoja, setCategoriasLoja] = useState([]);
    const [pecasLoja, setPecasLoja] = useState([]);
    const [subPecasLoja, setSubPecasLoja] = useState([]);

    const [tabSelecionada, setTabSelecionada] = useState(0);
    const [tabSelecionadaAnterior, setTabSelecionadaAnterior] = useState(0);

    useEffect(() => {
        if (userLoggedIn === null) return;

        if (carregouTela.current) return;
        carregouTela.current = true;


        (async () => {
            await fetchConfigs();
        })();


    }, [userLoggedIn]);

    useEffect(() => {
        if (!carregouTela.current) return;

        if (tabSelecionada < tabSelecionadaAnterior) {
            setTabSelecionadaAnterior(tabSelecionada);
            return;
        }

        (async () => {
            await fetchDados();

            setTabSelecionadaAnterior(tabSelecionada);
        })();
    }, [tabSelecionada]);


    const fetchConfigs = async () => {
        try {
            setLoading(true);

            //fetch generos
            const respSexo = await POST_DATA(`Sexo/GetAllSexo?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=SEX_Id`);
            setGeneros(respSexo);

            const respGenerosLoja = await POST_DATA(`UsuarioEmpresaXSexo/GetAllUsuarioEmpresaXSexoByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Id&Log=`);
            setGenerosLoja(respGenerosLoja);

            const respCategoriasEmpresa = await POST_DATA(`UsuarioEmpresaXCategoria/GetAllUsuarioEmpresaXCategoriaByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=100&order_by=idSexo&Log=`);
            setCategoriasLoja(respCategoriasEmpresa);


            const idsCategoria = [];

            const categoriaAcessorios = {
                Nome: 'Acessórios',
                FlagPai: true,
                FlagAcessorio: true,
                Categorias: []
            }

            const categoriaBeleza = {
                Nome: 'Beleza',
                FlagPai: true,
                FlagBeleza: true,
                Categorias: []
            }


            for (const genero of respGenerosLoja) {
                const respCategorias = await POST_DATA(`Categoria/GetCategoriaByIdSexoIN?sIdSexo=${genero.IdSexo}&Log=`);

                const categoriasSexo = [];
                categoriaBeleza.Categorias = [];
                categoriaAcessorios.Categorias = [];

                for (const categoriaLoja of respCategoriasEmpresa) {
                    idsCategoria.push(categoriaLoja.IdCategoria);
                }

                for (const categoria of respCategorias[0]?._Categoria) {
                    if (categoria.FlagAcessorio) {
                        categoriaAcessorios.Categorias.push(categoria);
                    }
                    else if (categoria.FlagBeleza) {
                        categoriaBeleza.Categorias.push(categoria);
                    }
                    else {
                        categoriasSexo.push(categoria);
                    }
                }

                categoriasSexo.push(categoriaAcessorios);
                categoriasSexo.push(categoriaBeleza);

                genero._Categoria = categoriasSexo;
            }

            setSexoXCategorias(respGenerosLoja);


            const respPecas = await POST_DATA(`Peca/GetPecasByIdCategorias?ids=${idsCategoria.join(',')}`);
            setPecas(respPecas);

            const respPecasLoja = await POST_DATA(`UsuarioEmpresaXPeca/GetAllUsuarioEmpresaXPecaByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=Up.IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=100&order_by=Up.idSexo&Log=`);
            const arrPecasLoja = [];
            const idsPecas = [];

            for (const pecaLoja of respPecasLoja) {
                const item = respPecas.filter(a => a.IdPeca === pecaLoja.IdPeca && a.IdSexo === pecaLoja.IdSexo && respCategoriasEmpresa.filter(b => b.IdCategoria === a.IdCategoria && b.IdSexo === pecaLoja.IdSexo).length > 0)[0];

                if (item === undefined) continue;

                pecaLoja.IdCategoria = item.IdCategoria;

                if (arrPecasLoja.filter(a => a.IdPeca === pecaLoja.IdPeca && a.IdCategoria === pecaLoja.IdCategoria && a.IdSexo === pecaLoja.IdSexo).length > 0) continue;

                arrPecasLoja.push(pecaLoja);
                idsPecas.push(pecaLoja.IdPeca);
            }

            setPecasLoja(arrPecasLoja);

            if (idsPecas.length > 0) {
                const subPecas = await POST_DATA(`SubPeca/GetSubPecaByIdsPecas?ids=${idsPecas.join(',')}`);
                const subPecass = [];

                for (const genero of respGenerosLoja) {
                    for (const subPeca of subPecas) {
                        if (subPecass.filter(a => a.IdSubPeca === subPeca.IdSubPeca && a.IdSexo === genero.IdSexo).length > 0) {
                            continue;
                        }

                        const item = respPecas.filter(a => a.IdPeca === subPeca.IdPeca && a.IdSexo === genero.IdSexo)[0];
                        if (item === undefined) continue;

                        const tmpSubPeca = {
                            IdSubPeca: subPeca.IdSubPeca,
                            IdPeca: subPeca.IdPeca,
                            IdCategoria: item.IdCategoria,
                            IdSexo: genero.IdSexo,
                            Nome: subPeca.Nome,
                            FlgAtivo: true
                        }

                        subPecass.push(tmpSubPeca);
                    }
                }

                setSubPecas(subPecass);

                //Sub peças da loja
                const arrSubPecasLoja = [];
                for (const subPecaLoja of respPecasLoja.filter(a => a.IdSubPeca > 0)) {
                    arrSubPecasLoja.push(subPecaLoja);
                }

                setSubPecasLoja(arrSubPecasLoja);
            }

            setLoading(false);
        }
        catch (err) {
            SalvarLogSentry(err);
            setLoading(false);
        }
    }

    const clickGenerosLoja = (genero) => {
        let arr = [...generosLoja];
        const item = generosLoja.filter(a => a.IdSexo === genero.SEX_Id)[0];

        if (item) {
            const possuiCategoria = categoriasLoja.filter(a => a.IdSexo === item.IdSexo).length > 0;

            if (possuiCategoria) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Essa ação não pode ser executada enquanto houver categorias vinculadas',
                });

                return;
            }
            arr = generosLoja.filter(a => a.IdSexo !== genero.SEX_Id);
        }
        else {
            arr.push({
                Id: 0,
                IdSexo: genero.SEX_Id,
                IdUsuarioEmpresa: userLoggedIn.IdUsuarioEmpresa,
                _Sexo: genero
            });
        }

        setGenerosLoja(arr);
    }

    const clickCategoriasLoja = (categoria, sexo, categoriaPai) => {
        const arr = [...categoriasLoja];
        const idx = categoriasLoja.findIndex(a => a.IdCategoria === categoria.IdCategoria && a.IdSexo === sexo.IdSexo);

        if (idx !== -1) {
            const possuiPecas = pecasLoja.filter(a => a.IdCategoria === categoria.IdCategoria && a.IdSexo === sexo.IdSexo).length > 0;
            if (possuiPecas) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Essa ação não pode ser executada enquanto houver peças vinculadas',
                });

                return;
            }

            arr.splice(idx, 1);
        }
        else {
            arr.push({
                Id: 0,
                IdCategoria: categoria.IdCategoria,
                IdUsuarioEmpresa: userLoggedIn.IdUsuarioEmpresa,
                IdSexo: sexo.IdSexo,
                Categoria: categoria,
                FlagBeleza: categoriaPai?.FlagBeleza || false,
                FlagAcessorio: categoriaPai?.FlagAcessorio || false,
                _Sexo: sexo?._Sexo
            });
        }

        setCategoriasLoja(arr);
    }

    const clickPecasLoja = (categoria, sexo, peca) => {
        const arr = [...pecasLoja];
        const idx = pecasLoja.findIndex(a => a.IdCategoria === categoria.IdCategoria && a.IdSexo === sexo.SEX_Id && a.IdPeca === peca.IdPeca);
        if (idx !== -1) {
            const possuiSubPecas = subPecasLoja.filter(a => a.IdPeca === peca.IdPeca && a.IdSexo === sexo.SEX_Id).length > 0;

            if (possuiSubPecas) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Essa ação não pode ser executada enquanto houver sub peças vinculadas',
                });

                return;
            }

            arr.splice(idx, 1);
        }
        else {
            arr.push({
                Id: 0,
                IdCategoria: categoria.IdCategoria,
                IdUsuarioEmpresa: userLoggedIn.IdUsuarioEmpresa,
                IdSexo: sexo.SEX_Id,
                IdPeca: peca.IdPeca,
                IdSubPeca: 0,

                Categoria: categoria,
                _Sexo: sexo,
                Peca: peca,
            });
        }

        setPecasLoja(arr);
    }

    const clickSubPecasLoja = (IdSexo, IdPeca, IdSubPeca) => {
        const arr = [...subPecasLoja];
        const idx = subPecasLoja.findIndex(a => a.IdSexo === IdSexo && a.IdPeca === IdPeca && a.IdSubPeca === IdSubPeca);

        if (idx !== -1) {
            arr.splice(idx, 1);
        }
        else {
            arr.push({
                Id: 0,
                IdUsuarioEmpresa: userLoggedIn.IdUsuarioEmpresa,
                IdSexo: IdSexo,
                IdPeca: IdPeca,
                IdSubPeca: IdSubPeca
            });
        }

        setSubPecasLoja(() => arr);
    }

    const fetchDados = async () => {
        if (!carregouTela.current) return;

        //if (1 === 1) return;

        try {
            setLoadingDados(true);

            const idsCategoria = [];
            const arrCategoriasLoja = [];
            const arrPecasLoja = [];
            const idsPecas = [];

            const categoriaAcessorios = {
                Nome: 'Acessórios',
                FlagPai: true,
                FlagAcessorio: true,
                Categorias: []
            }

            const categoriaBeleza = {
                Nome: 'Beleza',
                FlagPai: true,
                FlagAcessorio: true,
                Categorias: []
            }

            for (const genero of generosLoja) {
                for (const catLoja of categoriasLoja.filter(a => a.IdSexo === genero.IdSexo)) {
                    arrCategoriasLoja.push(catLoja);
                }

                const categoriasSexo = [];
                categoriaBeleza.Categorias = [];
                categoriaAcessorios.Categorias = [];

                const respCategorias = await POST_DATA(`Categoria/GetCategoriaByIdSexoIN?sIdSexo=${genero.IdSexo}&Log=`);

                for (const cartegoriaLoja of categoriasLoja) {
                    idsCategoria.push(cartegoriaLoja.IdCategoria);
                }

                for (const categoria of respCategorias[0]?._Categoria) {
                    if (categoria.FlagAcessorio) {
                        categoriaAcessorios.Categorias.push(categoria);
                    }
                    else if (categoria.FlagBeleza) {
                        categoriaBeleza.Categorias.push(categoria);
                    }
                    else {
                        categoriasSexo.push(categoria);
                    }
                }

                categoriasSexo.push(categoriaAcessorios);
                categoriasSexo.push(categoriaBeleza);

                genero._Categoria = categoriasSexo;
            }

            setCategoriasLoja(arrCategoriasLoja);
            setSexoXCategorias(generosLoja);

            const respPecas = await POST_DATA(`Peca/GetPecasByIdCategorias?ids=${idsCategoria.join(',')}`);

            setPecas(respPecas);

            for (const pecaLoja of pecasLoja) {
                if (respPecas.filter(a => a.IdPeca === pecaLoja.IdPeca).length === 0) continue;

                arrPecasLoja.push(pecaLoja);

                if (idsPecas.filter(a => a === pecaLoja.IdPeca).length > 0) continue;
                idsPecas.push(pecaLoja.IdPeca);
            }

            setPecasLoja(arrPecasLoja);

            const subPecas = await POST_DATA(`SubPeca/GetSubPecaByIdsPecas?ids=${idsPecas.join(',')}`);
            setSubPecas([]);

            if(subPecas === null){
                setLoadingDados(false);
                return;
            }

            const subPecass = [];
            for (const genero of generosLoja) {
                for (const subPeca of subPecas) {
                    if (subPecass.filter(a => a.IdSubPeca === subPeca.IdSubPeca && a.IdSexo === genero.IdSexo).length > 0) {
                        continue;
                    }

                    const item = respPecas.filter(a => a.IdPeca === subPeca.IdPeca && a.IdSexo === genero.IdSexo)[0];
                    if (item === undefined) continue;

                    const tmpSubPeca = {
                        IdSubPeca: subPeca.IdSubPeca,
                        IdPeca: subPeca.IdPeca,
                        IdCategoria: item.IdCategoria,
                        IdSexo: genero.IdSexo,
                        Nome: subPeca.Nome,
                        FlgAtivo: true
                    }

                    subPecass.push(tmpSubPeca);
                }
            }

            setSubPecas(subPecass);

            const arrSubPecasLoja = [];

            for (const subPeca of subPecass) {
                if (subPecasLoja.filter(a => a.IdSubPeca === subPeca.IdSubPeca && a.IdSexo === subPeca.IdSexo).length > 0) {
                    arrSubPecasLoja.push(subPeca);
                }
            }

            setSubPecasLoja(arrSubPecasLoja);

            setLoadingDados(false);
        }
        catch (err) {
            setLoadingDados(false);
            SalvarLogSentry(err);
        }
        finally {
            setLoadingDados(false);
        }
    }

    const handleSalvar = async () => {
        try {
            for (const genero of generosLoja) {
                genero.FlagAtivo = true;
            }
            await PUT_DATA(`UsuarioEmpresaXSexo/InsertListUsuarioEmpresaXSexo`, generosLoja);

            for (const categoria of categoriasLoja) {
                categoria.FlagAtivo = true;
            }
            await PUT_DATA(`UsuarioEmpresaXCategoria/InsertListUsuarioEmpresaXCategoria`, categoriasLoja);

            for (const peca of pecasLoja) {
                peca.IdUsuarioEmpresa = userLoggedIn.IdUsuarioEmpresa;
                peca.IdSubPeca = 0;
                peca.FlagAtivo = true;
            }
            for (const subPeca of subPecasLoja) {
                subPeca.IdUsuarioEmpresa = userLoggedIn.IdUsuarioEmpresa;
                subPeca.FlagAtivo = true;
            }

            const pecasESubPecas = [...pecasLoja, ...subPecasLoja];

            await PUT_DATA(`UsuarioEmpresaXPeca/InsertListUsuarioEmpresaXPeca`, pecasESubPecas);

            await fetchConfigs();

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sucesso ao salvar os dados',
            });

        }
        catch (err) {
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Ocorreu um erro ao salvar os dados',
            });

            SalvarLogSentry(err);
        }
    }

    const handleChange = (event, newValue) => {
        setTabSelecionada(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    try {
        return (loading || loadingDados) ? <LoadingAllPage text="Carregando dados..." /> : <Container>
            <ConfirmationSalvar
                title="Deseja realmente prosseguir?"
                mensagem="Ao salvar, você confirma os riscos em alterar os dados de configuração dos produtos."
                btn1={{ title: "Cancelar", onClick: null }}
                btn2={{
                    title: "Salvar",
                    onClick: async () => {
                        await handleSalvar();
                    }
                }}
            />

            <Paper sx={{ width: "100%", overflow: "hidden", padding: 3 }}>
                <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                    <Typography style={{ fontWeight: 'bold' }}>Loja / Configuração</Typography>

                    <Button variant="contained" onClick={() => getConfirmationSalvar()}>Salvar</Button>
                </Box>

                <Divider style={{ marginTop: -10, marginBottom: 10 }} />

                <Tabs value={tabSelecionada} onChange={handleChange} aria-label="basic tabs example">
                    <Tab style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }} label="GÊNERO" {...a11yProps(0)} />
                    <Tab style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }} label="CATEGORIA" {...a11yProps(1)} />
                    <Tab style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }} label="SUB CATEGORIA" {...a11yProps(2)} />
                    <Tab style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }} label="GRUPO" {...a11yProps(3)} />

                </Tabs>

                <Divider />

                {
                    tabSelecionada === 0 &&
                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        {
                            generos.map((genero, index) => {
                                return <Button
                                    key={`genero-${index}`}
                                    style={{ marginTop: 15, fontWeight: 'bold', background: generosLoja?.filter(a => a.IdSexo === genero.SEX_Id).length > 0 ? '#f1416c' : '#f6f6f6', color: generosLoja?.filter(a => a.IdSexo === genero.SEX_Id).length > 0 ? 'white' : 'black', height: 60, width: 180, marginRight: 20 }}
                                    onClick={() => {
                                        clickGenerosLoja(genero);
                                    }}
                                >
                                    {genero.SEX_Nome}
                                </Button>
                            })
                        }
                    </Box>
                }
                {
                    tabSelecionada === 1 &&
                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingTop: 20 }}>
                        {
                            generosLoja.length > 0 && sexoXCategoria.map((genero, index) => {
                                return <Box key={`sexo-${genero.IdSexo}-${index}`} style={{ width: '100%', maxWidth: '900px', marginBottom: 20 }}>
                                    <Typography style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 15, borderRadius: 15, background: '#f1416c', color: 'white', width: 150, padding: 5, textAlign: 'center' }}>{genero._Sexo?.SEX_Nome}</Typography>

                                    <Box style={{ width: '100%', display: 'flex', flexWrap: 'wrap', overflowY: 'auto' }}>
                                        {
                                            genero._Categoria.map((categoria, idx) => {
                                                if (categoria.FlagPai) {
                                                    return <Box key={`grupo-categoria-${idx}`} style={{ width: '100%' }}>
                                                        <Typography style={{ fontWeight: 'bold', marginTop: 20 }}>{categoria.Nome}</Typography>
                                                        {
                                                            categoria.Categorias.map((catcat, idxIdx) => {
                                                                return <Button
                                                                    key={`genero-${genero.IdSexo}-${idx}-${idxIdx}`}
                                                                    style={{ marginTop: 15, fontWeight: 'bold', background: categoriasLoja?.filter(a => a.IdCategoria === catcat.IdCategoria && a.IdSexo === genero.IdSexo).length > 0 ? '#f1416c' : '#f6f6f6', color: categoriasLoja?.filter(a => a.IdCategoria === catcat.IdCategoria && a.IdSexo === genero.IdSexo).length > 0 ? 'white' : 'black', height: 60, width: 180, marginRight: 20 }}
                                                                    onClick={() => clickCategoriasLoja(catcat, genero, categoria)}
                                                                >
                                                                    {catcat.Descricao}
                                                                </Button>
                                                            })
                                                        }
                                                    </Box>
                                                }
                                                else {
                                                    return <Button
                                                        key={`genero-${genero.IdSexo}-${idx}`}
                                                        style={{ marginTop: 15, fontWeight: 'bold', background: categoriasLoja?.filter(a => a.IdCategoria === categoria.IdCategoria && a.IdSexo === genero.IdSexo).length > 0 ? '#f1416c' : '#f6f6f6', color: categoriasLoja?.filter(a => a.IdCategoria === categoria.IdCategoria && a.IdSexo === genero.IdSexo).length > 0 ? 'white' : 'black', height: 60, width: 180, marginRight: 20 }}
                                                        onClick={() => clickCategoriasLoja(categoria, genero)}
                                                    >
                                                        {categoria.Descricao}
                                                    </Button>
                                                }
                                            })
                                        }
                                    </Box>

                                    <Divider style={{ width: '100%', marginTop: 25, marginBottom: 15 }} />
                                </Box>
                            })
                        }
                        {
                            generosLoja.length === 0 &&
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ marginTop: 20, fontSize: 18, fontWeight: 'bold' }}>
                                    Favor selecionar ao menos um tipo de sexo.
                                </Typography>
                            </Box>
                        }
                    </Box>
                }
                {
                    tabSelecionada === 2 &&
                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingTop: 20 }}>
                        {
                            generosLoja.length > 0 && categoriasLoja.map((item, index) => {
                                return <Box key={`sexo-${item.IdSexo}-categoria-${item.Categoria.IdCategoria}-${index}`} style={{ width: '100%', maxWidth: '900px', marginBottom: 20 }}>
                                    <Typography style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 15 }}>{item?.Categoria?.Descricao} - {item?._Sexo?.SEX_Nome}</Typography>

                                    <Box style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                        {/* {
                                            (item.FlagBeleza || item.FlagAcessorio) && pecas.filter(a => a.IdCategoria === item.IdCategoria).map(peca => {
                                                return <Button
                                                    key={`sexo-${peca.IdSexo}-categoria-${peca.IdCategoria}-peca-${peca.IdPeca}`}
                                                    style={{ marginTop: 15, fontWeight: 'bold', background: pecasLoja?.filter(a => a.IdPeca === peca.IdPeca && a.IdCategoria === item.IdCategoria && a.IdSexo === item.IdSexo).length > 0 ? '#f1416c' : '#f6f6f6', color: pecasLoja?.filter(a => a.IdPeca === peca.IdPeca && a.IdCategoria === item.IdCategoria && a.IdSexo === item.IdSexo).length > 0 ? 'white' : 'black', height: 60, width: 180, marginRight: 20 }}
                                                    onClick={() => clickPecasLoja(item.Categoria, item._Sexo, peca)}
                                                >
                                                    {peca.Nome}
                                                </Button>
                                            })
                                        }
                                        {
                                            (!item.FlagBeleza && !item.FlagAcessorio) && pecas.filter(a => a.IdCategoria === item.IdCategoria && a.IdSexo === item.IdSexo).map(peca => {
                                                return <Button
                                                    key={`sexo-${peca.IdSexo}-categoria-${peca.IdCategoria}-peca-${peca.IdPeca}`}
                                                    style={{ marginTop: 15, fontWeight: 'bold', background: pecasLoja?.filter(a => a.IdPeca === peca.IdPeca && a.IdCategoria === item.IdCategoria && a.IdSexo === item.IdSexo).length > 0 ? '#f1416c' : '#f6f6f6', color: pecasLoja?.filter(a => a.IdPeca === peca.IdPeca && a.IdCategoria === item.IdCategoria && a.IdSexo === item.IdSexo).length > 0 ? 'white' : 'black', height: 60, width: 180, marginRight: 20 }}
                                                    onClick={() => clickPecasLoja(item.Categoria, item._Sexo, peca)}
                                                >
                                                    {peca.Nome}
                                                </Button>
                                            })
                                        } */}

                                        {
                                            pecas?.filter(a => a.IdCategoria === item.IdCategoria && a.IdSexo === item.IdSexo).map(peca => {
                                                return <Button
                                                    key={`sexo-${peca.IdSexo}-categoria-${peca.IdCategoria}-peca-${peca.IdPeca}`}
                                                    style={{ marginTop: 15, fontWeight: 'bold', background: pecasLoja?.filter(a => a.IdPeca === peca.IdPeca && a.IdCategoria === item.IdCategoria && a.IdSexo === item.IdSexo).length > 0 ? '#f1416c' : '#f6f6f6', color: pecasLoja?.filter(a => a.IdPeca === peca.IdPeca && a.IdCategoria === item.IdCategoria && a.IdSexo === item.IdSexo).length > 0 ? 'white' : 'black', height: 60, width: 180, marginRight: 20 }}
                                                    onClick={() => clickPecasLoja(item.Categoria, item._Sexo, peca)}
                                                >
                                                    {peca.Nome}
                                                </Button>
                                            })
                                        }
                                    </Box>
                                    <Divider style={{ width: '100%', marginTop: 20 }} />
                                </Box>
                            })
                        }
                        {
                            generosLoja.length === 0 &&
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ marginTop: 20, fontSize: 18, fontWeight: 'bold' }}>
                                    Favor selecionar ao menos um tipo de sexo.
                                </Typography>
                            </Box>
                        }
                        {
                            categoriasLoja.length === 0 &&
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ marginTop: 20, fontSize: 18, fontWeight: 'bold' }}>
                                    Favor selecionar ao menos um tipo de categoria.
                                </Typography>
                            </Box>
                        }
                    </Box>
                }
                {
                    tabSelecionada === 3 &&
                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingTop: 20 }}>
                        {
                            generosLoja.length > 0 && pecasLoja.length > 0 && sexoXCategoria.map(sexoXCat => {

                                return <Box key={`sexo_${sexoXCat.IdSexo}`} style={{ width: '100%' }}>
                                    <Typography style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 15 }}>{sexoXCat._Sexo.SEX_Nome}</Typography>

                                    {
                                        pecasLoja?.filter(a => a.IdSexo === sexoXCat.IdSexo).map(peca => {
                                            return <Box key={`pecas-${peca.IdPeca}`}>
                                                <Typography style={{ marginLeft: 50, fontSize: 18, fontWeight: 'bold', marginTop: 15, marginBottom: 7 }}>{categoriasLoja.filter(a => a.IdCategoria === peca.IdCategoria)[0]?.Categoria?.Descricao} - {peca?._Peca?.Nome || peca?.Peca?.Nome}</Typography>

                                                <Box style={{ width: '100%', paddingLeft: 80, display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                                    {
                                                        subPecas?.filter(a => a.IdPeca === peca.IdPeca && a.IdCategoria === peca.IdCategoria && a.IdSexo === sexoXCat.IdSexo).map((item, index) => {
                                                            return <Button
                                                                key={`sexo-${sexoXCat.IdSexo}-peca-${item.IdPeca}-subPeca-${item.IdSubPeca}-${index}`}
                                                                style={{ marginTop: 15, fontWeight: 'bold', background: subPecasLoja?.filter(a => a.IdSexo === sexoXCat.IdSexo && a.IdSubPeca === item.IdSubPeca).length > 0 ? '#f1416c' : '#f6f6f6', color: subPecasLoja?.filter(a => a.IdSexo === sexoXCat.IdSexo && a.IdSubPeca === item.IdSubPeca).length > 0 ? 'white' : 'black', height: 60, width: 180, marginRight: 20 }}
                                                                onClick={() => clickSubPecasLoja(sexoXCat.IdSexo, item.IdPeca, item.IdSubPeca)}
                                                            >
                                                                {item.Nome}
                                                            </Button>
                                                        })
                                                    }
                                                    {
                                                        subPecas?.filter(a => a.IdPeca === peca.IdPeca && a.IdCategoria === peca.IdCategoria && a.IdSexo === sexoXCat.IdSexo).length === 0 &&
                                                        <Typography style={{ marginTop: 20, fontSize: 18, fontWeight: 'bold' }}>
                                                            Não há itens disponíveis
                                                        </Typography>
                                                    }
                                                </Box>
                                            </Box>
                                        })
                                    }

                                    <Divider style={{ width: '100%', marginTop: 20, marginBottom: 10 }} />
                                </Box>
                            })
                        }
                        {
                            generosLoja.length === 0 &&
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ marginTop: 20, fontSize: 18, fontWeight: 'bold' }}>
                                    Favor selecionar ao menos um tipo de sexo.
                                </Typography>
                            </Box>
                        }
                        {
                            categoriasLoja.length === 0 &&
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ marginTop: 20, fontSize: 18, fontWeight: 'bold' }}>
                                    Favor selecionar ao menos um tipo de categoria.
                                </Typography>
                            </Box>
                        }
                        {
                            pecasLoja.length === 0 &&
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ marginTop: 20, fontSize: 18, fontWeight: 'bold' }}>
                                    Favor selecionar ao menos um tipo de peça.
                                </Typography>
                            </Box>
                        }
                    </Box>
                }
            </Paper>
        </Container>
    }
    catch (err) {
        dispatch({
            type: CONTROLEMENSAGEM_ERRO,
            tipoComponente: 'alert',
            titulo: 'Aviso',
            message: `Ocorreu um erro: ${err.message}`,
        });
        SalvarLogSentry(err);
    }
}

export default Configuracao;